import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationStart, Router, NavigationEnd } from '@angular/router';
import { AttributeService } from 'src/app/core/service/service.index';
import { VersionService } from 'src/app/services/version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  public loading: boolean = false;

  constructor(
    private _router: Router,
    private _attribute_service: AttributeService,
    private _version: VersionService
  ) {
    this.loaderOnRouteChange();
  }

  ngOnInit(): void {
    console.log('AppComponent ngOnInit');
    this._version.watch();
    this.initScripts();
  }

  ngOnDestroy(): void {
    console.log('AppComponent ngOnDestroy');
  }

  ngAfterViewChecked(): void {
    console.log('AppComponent ngAfterViewChecked');
  }

  public initScripts(): void {
    function resizeInnerDiv() {
      var height: any = $(window).height();
      var header_height: any = $('.header').height();
      var footer_height: any = $('.footer').height();
      var setheight = height - header_height;
      var trueheight = setheight - footer_height;
      $('.content').css('min-height', trueheight);
    }

    $(window).resize(function () {
      if ($('.content').length > 0) {
        resizeInnerDiv();
      }
    });
    if ($('.content').length > 0) {
      resizeInnerDiv();
    }
    window.dispatchEvent(new Event('resize'));
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav');
      }
    });
    this._attribute_service.usersideMenuresponsive();
  }

  public loaderOnRouteChange(): void {
    console.log('AppComponent loaderOnRouteChange');
    this._router.events.subscribe(async (val: any) => {
      if (val instanceof NavigationStart) {
        this.loading = true;
      }

      if (val instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }
}
