import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export const API_URI = environment.base_uri;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public userEmitter = new BehaviorSubject(null);
  public userObservable = this.userEmitter.asObservable();

  constructor(private _http: HttpClient) {}

  /** GET REQUEST */
  public get(url: string): Observable<any> {
    return this._http.get(`${API_URI}/${url}`, this._getOptions());
  }

  /** POST REQUEST */
  public post(url: string, params: any): Observable<any> {
    return this._http.post(`${API_URI}/${url}`, params, this._getOptions());
  }

  /** PUT REQUEST */
  public put(url: string, params: any): Observable<any> {
    return this._http.put(`${API_URI}/${url}`, params, this._getOptions());
  }

  /** DELETE REQUEST */
  public delete(url: string, id: number): Observable<any> {
    return this._http.delete(`${url}/${id}`, this._getOptions());
  }

  /** HTTP HEADERS */
  private _getOptions() {
    let token: string | null = localStorage.getItem('token');

    let httpOptions = {
      headers: new HttpHeaders({
        observe: 'response',
        responseType: 'json',
        Authorization: 'Bearer ' + token,
      }),
    };
    return httpOptions;
  }

  public getImage(url: string): Observable<any> {
    return this._http.get(`${API_URI}/${url}`, {
      headers: this._getBlobOptions(),
      responseType: 'blob',
    });
  }

  private _getBlobOptions() {
    let token: string | null = localStorage.getItem('token');

    let httpOptions = {
      Authorization: 'Bearer ' + token,
    };
    return httpOptions;
  }
}
