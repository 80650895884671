import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}
  createDb() {
    let specialityList = [
      {
        id: 1,
        key: '#4546',
        jobspeciality: 'Urology',
        img: 'assets/admin/img/icon/urology.png',
      },
      {
        id: 2,
        key: '#4546',
        jobspeciality: 'Orthopedic',
        img: 'assets/admin/img/icon/ortho.png',
      },
      {
        id: 3,
        key: '#4546',
        jobspeciality: 'Cardiologis',
        img: 'assets/admin/img/icon/cardio.png',
      },
      {
        id: 4,
        key: '#4546',
        jobspeciality: 'Dentist',
        img: 'assets/admin/img/icon/dental.png',
      },
      {
        id: 5,
        key: '#8774',
        jobspeciality: 'Neurology',
        img: 'assets/admin/img/icon/neurology.png',
      },
    ];

    let appointments = [
      {
        id: 1,
        doctorName: 'Dr. Ruby Perrin',
        type: 'New patient',
        speciality: 'Dental',
        patient_key: '#PT001',
        Patient_Name: 'Charlene Reed',
        appointment_time:
          'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'active',
        amount: '$200.00',
      },
      {
        id: 2,
        doctorName: 'Dr. Darren Eldder',
        type: 'New patient',
        speciality: 'Dental',
        patient_key: '#PT002',
        Patient_Name: 'Travis Trimble',
        appointment_time:
          'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'active',
        amount: '$300.00',
      },
      {
        id: 3,
        doctorName: 'Dr. Deborah Angel',
        type: 'Old patient',
        speciality: 'Cardiology',
        patient_key: '#PT003',
        Patient_Name: 'Carl Kelly',
        appointment_time:
          'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'active',
        amount: '$150.00',
      },
      {
        id: 4,
        doctorName: 'Dr. Sofia Brient',
        type: 'Old patient',
        speciality: 'Urology',
        patient_key: '#PT004',
        Patient_Name: 'Michelle Fairfax',
        appointment_time:
          'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'active',
        amount: '$150.00',
      },
      {
        id: 5,
        doctorName: 'Dr. Marvin Campbell',
        type: 'New patient',
        speciality: 'Urology',
        patient_key: '#PT005',
        Patient_Name: 'Gina Moore',
        appointment_time:
          'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        status: 'active',
        amount: '$200.00',
      },
    ];

    let favourites = [
      {
        id: 1,
        doctor_name: 'Dr. Ruby Perrin',
        speciality: 'Dentist',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MDS - Periodontology and Oral Implantology, BDS',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '3100.00',
        Price: '$160',
        profile: 'assets/img/doctors/doctor-01.jpg',
        status: '1',
        type: 'Female',
        location: 'Georgia, USA',
        availableTime: '10 am',
        consulting_fees: '$100',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 2,
        doctor_name: 'Dr. Darren Elder',
        speciality: 'Surgery',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'BDS, MDS - Oral & Maxillofacial Surgery',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '3900.00',
        Price: '$190',
        profile: 'assets/img/doctors/doctor-02.jpg',
        status: '1',
        type: 'Female',
        location: 'Montana, USA',
        availableTime: '8 am',
        consulting_fees: '$90',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 3,
        doctor_name: 'Dr. Deborah Angel',
        speciality: 'Cardiology',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MBBS, MD - General Medicine, DNB - Cardiology',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '3700.00',
        Price: '$170',
        profile: 'assets/img/doctors/doctor-03.jpg',
        status: '1',
        type: 'Female',
        location: 'Oklahoma, USA',
        availableTime: '2 pm',
        consulting_fees: '$70',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 4,
        doctor_name: 'Dr. Sofia Brient',
        speciality: 'BDS, MDS - Oral & Maxillofacial Surgery',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MBBS, MS - General Surgery, MCh - Urology',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '3000.00',
        Price: '$110',
        profile: 'assets/img/doctors/doctor-04.jpg',
        status: '1',
        type: 'Female',
        location: 'Georgia, USA',
        availableTime: '11 am',
        consulting_fees: '$110',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 5,
        doctor_name: 'Dr. Marvin Campbell',
        speciality: 'MBBS, MD - Ophthalmology, DNB - Ophthalmology',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MBBS, MD - Ophthalmology, DNB - Ophthalmology',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '3150.00',
        Price: '$130',
        profile: 'assets/img/doctors/doctor-05.jpg',
        status: '1',
        type: 'Male',
        location: 'Florida, USA',
        availableTime: '12 pm',
        consulting_fees: '$80',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 6,
        doctor_name: 'Dr. Katharine Berthold',
        speciality: 'Dentist',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MS - Orthopaedics, MBBS, M.Ch - Orthopaedics',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '2900.00',
        Price: '$180',
        profile: 'assets/img/doctors/doctor-06.jpg',
        status: '1',
        type: 'Female',
        location: 'Newyork, USA',
        availableTime: '7 am',
        consulting_fees: '$170',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 7,
        doctor_name: 'Dr. Linda Tobin',
        speciality: 'Dentist',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MBBS, MD - General Medicine, DM - Neurology',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '2900.00',
        Price: '$160',
        profile: 'assets/img/doctors/doctor-07.jpg',
        status: '1',
        type: 'Female',
        location: 'Texas, USA',
        availableTime: '10 pm',
        consulting_fees: '$130',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 8,
        doctor_name: 'Dr. Julia Washington',
        speciality: 'Dentist',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MBBS, MD - General Medicine, DM - Endocrinology',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '3200.00',
        Price: '$140',
        profile: 'assets/img/doctors/doctor-08.jpg',
        status: '1',
        type: 'Female',
        location: 'Louisiana, USA',
        availableTime: '9 am',
        consulting_fees: '$90',
        booking_fees: '$10',
        video_call: '$50',
      },
      {
        id: 9,
        doctor_name: 'Dr. Paul Richard',
        speciality: 'Dentist',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MBBS, MD - Dermatology , Venereology & Lepros',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '3000.00',
        Price: '$150',
        profile: 'assets/img/doctors/doctor-09.jpg',
        status: '1',
        type: 'Male',
        location: 'Louisiana, USA',
        availableTime: '10 am',
        consulting_fees: '$120',
        booking_fees: '$10',
        video_call: '$50',
      },
    ];

    let doctors = [
      {
        id: 1,
        doctor_name: 'Ruby Perrin',
        speciality: 'Dental',
        speciality_profile: 'assets/img/specialities/specialities-05.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MDS - Periodontology and Oral Implantology, BDS',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '$300 - $1000',
        Price: '$160',
        profile: 'assets/img/doctors/doctor-01.jpg',
        status: '1',
        type: 'Female',
        location: ' Florida, USA',
        availableTime: '10 am',
        consulting_fees: '$100',
        booking_fees: '$10',
        video_call: '$50',
        span: '17',
      },
      {
        id: 2,
        doctor_name: 'Darren Elder',
        speciality: 'Cardiology',
        speciality_profile: 'assets/img/specialities/specialities-04.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '5000.00',
        Education: 'BDS, MDS - Oral & Maxillofacial Surgery',
        Available: ' Available on Fri, 22 Mar ',
        Price: ' $50 - $300',
        status: '1',
        profile: 'assets/img/doctors/doctor-02.jpg',
        type: 'Female',
        location: 'Newyork, USA',
        availableTime: '10 am',
        consulting_fees: '$100',
        booking_fees: '$10',
        video_call: '$50',
        span: '35',
      },
      {
        id: 3,
        doctor_name: 'Deborah Angel',
        speciality: 'Dental',
        speciality_profile: 'assets/img/specialities/specialities-05.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3300.00',
        Education: 'MBBS, MD - General Medicine, DNB - Cardiology',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$100 - $400',
        status: '1',
        profile: 'assets/img/doctors/doctor-03.jpg',
        type: 'Male',
        location: 'Georgia, USA',
        availableTime: '3 pm',
        consulting_fees: '$150',
        booking_fees: '$10',
        video_call: '$50',
        span: '27',
      },
      {
        id: 4,
        doctor_name: 'Sofia Brient',
        speciality: 'Orthopaedics',
        speciality_profile: 'assets/img/specialities/specialities-03.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3500.00',
        Education: 'MBBS, MS - General Surgery, MCh - Urology',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$150 - $250',
        status: '1',
        profile: 'assets/img/doctors/doctor-04.jpg',
        type: 'Male',
        location: 'Louisiana, USA',
        availableTime: '12 am',
        consulting_fees: '$250',
        booking_fees: '$10',
        video_call: '$50',
        span: '4',
      },
      {
        id: 5,
        doctor_name: 'Marvin Campbell',
        speciality: 'Neurology',
        speciality_profile: 'assets/img/specialities/specialities-02.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3700.00',
        Education: 'MBBS, MD - Ophthalmology, DNB - Ophthalmology',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$50 - $700',
        status: '1',
        profile: 'assets/img/doctors/doctor-05.jpg',
        type: 'Male',
        location: 'Michigan, USA',
        availableTime: '11 am',
        consulting_fees: '$200',
        booking_fees: '$10',
        video_call: '$50',
        span: '66',
      },
      {
        id: 6,
        doctor_name: 'Katharine Berthold',
        speciality: 'Orthopaedics',
        speciality_profile: 'assets/img/specialities/specialities-03.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3700.00',
        Education: 'MS - Orthopaedics, MBBS, M.Ch - Orthopaedics',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$100 - $500',
        status: '1',
        profile: 'assets/img/doctors/doctor-06.jpg',
        type: 'Male',
        location: 'Texas, USA',
        availableTime: '11 am',
        consulting_fees: '$200',
        booking_fees: '$10',
        video_call: '$50',
        span: '52',
      },
      {
        id: 7,
        doctor_name: 'Linda Tobin',
        speciality: 'Dental',
        speciality_profile: 'assets/img/specialities/specialities-05.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3700.00',
        Education: 'MBBS, MD - General Medicine, DM - Neurology',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$100 - $1000',
        status: '1',
        profile: 'assets/img/doctors/doctor-07.jpg',
        type: 'Male',
        location: 'Kansas, USA',
        availableTime: '11 am',
        consulting_fees: '$200',
        booking_fees: '$10',
        video_call: '$50',
        span: '43',
      },
      {
        id: 8,
        doctor_name: 'Paul Richard',
        speciality: 'Neurology',
        speciality_profile: 'assets/img/specialities/specialities-02.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3700.00',
        Education: 'MBBS, MD - Dermatology , Venereology & Lepros',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$100 - $400',
        status: '1',
        profile: 'assets/img/doctors/doctor-08.jpg',
        type: 'Male',
        location: 'California, USA',
        availableTime: '11 am',
        consulting_fees: '$200',
        booking_fees: '$10',
        video_call: '$50',
        span: '49',
      },
    ];

    let searchDoctor = [
      {
        id: 1,
        doctor_name: 'Ruby Perrin',
        speciality: 'Dental',
        speciality_profile: 'assets/img/specialities/specialities-05.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Education: 'MDS - Periodontology and Oral Implantology, BDS',
        Available: ' Available on Fri, 22 Mar ',
        Earned: '$300 - $1000',
        Price: '$160',
        profile: 'assets/img/doctors/doctor-thumb-01.jpg',
        status: '1',
        type: 'Female',
        location: ' Florida, USA',
        availableTime: '10 am',
        consulting_fees: '$100',
        booking_fees: '$10',
        video_call: '$50',
        span: '17',
      },
      {
        id: 2,
        doctor_name: 'Darren Elder',
        speciality: 'Cardiology',
        speciality_profile: 'assets/img/specialities/specialities-04.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '5000.00',
        Education: 'BDS, MDS - Oral & Maxillofacial Surgery',
        Available: ' Available on Fri, 22 Mar ',
        Price: ' $50 - $300',
        status: '1',
        profile: 'assets/img/doctors/doctor-thumb-02.jpg',
        type: 'Female',
        location: 'Newyork, USA',
        availableTime: '10 am',
        consulting_fees: '$100',
        booking_fees: '$10',
        video_call: '$50',
        span: '35',
      },
      {
        id: 3,
        doctor_name: 'Deborah Angel',
        speciality: 'Dental',
        speciality_profile: 'assets/img/specialities/specialities-05.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3300.00',
        Education: 'MBBS, MD - General Medicine, DNB - Cardiology',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$100 - $400',
        status: '1',
        profile: 'assets/img/doctors/doctor-thumb-03.jpg',
        type: 'Male',
        location: 'Georgia, USA',
        availableTime: '3 pm',
        consulting_fees: '$150',
        booking_fees: '$10',
        video_call: '$50',
        span: '27',
      },
      {
        id: 4,
        doctor_name: 'Sofia Brient',
        speciality: 'Orthopaedics',
        speciality_profile: 'assets/img/specialities/specialities-03.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3500.00',
        Education: 'MBBS, MS - General Surgery, MCh - Urology',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$150 - $250',
        status: '1',
        profile: 'assets/img/doctors/doctor-thumb-04.jpg',
        type: 'Male',
        location: 'Louisiana, USA',
        availableTime: '12 am',
        consulting_fees: '$250',
        booking_fees: '$10',
        video_call: '$50',
        span: '4',
      },
      {
        id: 5,
        doctor_name: 'Katharine Berthold',
        speciality: 'Orthopaedics',
        speciality_profile: 'assets/img/specialities/specialities-03.png',
        since: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        Earned: '3700.00',
        Education: 'MS - Orthopaedics, MBBS, M.Ch - Orthopaedics',
        Available: ' Available on Fri, 22 Mar ',
        Price: '$100 - $500',
        status: '1',
        profile: 'assets/img/doctors/doctor-thumb-06.jpg',
        type: 'Male',
        location: 'Texas, USA',
        availableTime: '11 am',
        consulting_fees: '$200',
        booking_fees: '$10',
        video_call: '$50',
        span: '52',
      },
    ];
    let patients = [
      {
        id: '1',
        key: '#P0016',
        name: 'Richard Wilson',
        age: '38',
        address: 'Alabama, USA',
        phone: '1 952 001 8563',
        email: 'richardwilson@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '100.00',
        bloodgroup: 'AB+',
        type: 'Male',
        img: 'assets/img/patients/patient.jpg',
      },
      {
        id: '2',
        key: '#PT001',
        name: 'Charlene Reed',
        age: '29',
        address: 'North Carolina, USA',
        phone: '1 828 632 9170',
        email: 'Charlene@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '100.00',
        bloodgroup: 'o+',
        type: 'Female',
        img: 'assets/img/patients/patient1.jpg',
      },
      {
        id: '3',
        key: '#PT002',
        name: 'Travis Trimble',
        age: '23',
        address: 'Maine, USA',
        phone: '1 207 729 9974',
        email: 'travis@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '250.00',
        bloodgroup: 'B+',
        type: 'Male',
        img: 'assets/img/patients/patient2.jpg',
      },
      {
        id: '4',
        key: '#PT003',
        name: 'Carl Kelly',
        age: '29',
        address: 'Indiana, USA',
        phone: '1 260 724 7769',
        email: 'carlkelly@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '200.00',
        bloodgroup: 'A+',
        type: 'Male',
        img: 'assets/img/patients/patient3.jpg',
      },
      {
        id: '5',
        key: '#PT004',
        name: 'Michelle Fairfax',
        age: '25',
        address: 'Indiana, USA',
        phone: '1 504 368 6874',
        email: 'michelle@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '150.00',
        bloodgroup: 'B+',
        type: 'Male',
        img: 'assets/img/patients/patient4.jpg',
      },
      {
        id: '6',
        key: '#PT005',
        name: 'Gina Moore',
        age: '23',
        address: 'Florida, USA',
        phone: '9548207887',
        email: 'Elsie@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'o-',
        type: 'Male',
        img: 'assets/img/patients/patient5.jpg',
      },
      {
        id: '7',
        key: '#PT006',
        name: 'Elsie Gilley',
        age: '16',
        address: 'Kentucky, USA',
        phone: '9548207887',
        email: 'Elsie@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'o-',
        type: 'Male',
        img: 'assets/img/patients/patient6.jpg',
      },
      {
        id: '8',
        key: '#PT007',
        name: 'Joan Gardner',
        age: '25',
        address: 'California, USA',
        phone: '9548207887',
        email: 'Elsie@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'o-',
        type: 'Male',
        img: 'assets/img/patients/patient7.jpg',
      },
      {
        id: '9',
        key: '#PT008',
        name: 'Daniel Griffing',
        age: '21',
        address: 'New Jersey, USA',
        phone: '9548207887',
        email: 'Elsie@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'o-',
        type: 'Male',
        img: 'assets/img/patients/patient8.jpg',
      },
      {
        id: '10',
        key: '#PT009',
        name: 'Walter Roberson',
        age: '18',
        address: 'Florida, USA',
        phone: '9548207887',
        email: 'Elsie@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'o-',
        type: 'Male',
        img: 'assets/img/patients/patient9.jpg',
      },
      {
        id: '11',
        key: '#PT010',
        name: 'Robert Rhodes',
        age: '19',
        address: 'California, USA',
        phone: '9548207887',
        email: 'Elsie@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'o-',
        type: 'Male',
        img: 'assets/img/patients/patient10.jpg',
      },
      {
        id: '12',
        key: '#PT0011',
        name: 'Harry Williams',
        age: '9',
        address: 'Colorado, USA',
        phone: '1 303 607 7075',
        email: 'harrywilliams@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'A-',
        type: 'Male',
        img: 'assets/img/patients/patient11.jpg',
      },
      {
        id: '13',
        key: '#PT0011',
        name: 'Harry Williams',
        age: '9',
        address: 'Colorado, USA',
        phone: '1 303 607 7075',
        email: 'harrywilliams@example.com',
        lastvisit: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
        paid: '350.00',
        bloodgroup: 'A-',
        type: 'Male',
        img: 'assets/img/patients/patient12.jpg',
      },
    ];

    let reviews = [
      {
        id: 1,
        revid: '#4546',
        patient_name: 'Bess Twishes',
        doctor_name: 'Dr. Rayan',
        patient_pic: 'assets/admin/img/profiles/avatar-07.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-02.jpg',
        speciality: 'Gyanoclogist',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 2,
        revid: '#8774',
        patient_name: 'Bess Twishes',
        doctor_name: 'Dr. Monroe',
        patient_pic: 'assets/admin/img/profiles/avatar-12.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-05.jpg',
        speciality: 'Hair Specialities',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 3,
        revid: '#4546',
        patient_name: 'Abdul Aziz Lazis',
        doctor_name: 'Dr. Lester',
        patient_pic: 'assets/admin/img/profiles/avatar-02.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-05.jpg',
        speciality: 'Orthopaedics',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 4,
        revid: '#4546',
        patient_name: 'Alex Siauw',
        doctor_name: 'Dr. Clint',
        patient_pic: 'assets/admin/img/profiles/avatar-05.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-02.jpg',
        speciality: 'Neurology',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 5,
        revid: '#4546',
        patient_name: 'Dr. Sofia Brient',
        doctor_name: 'Dr. Lester',
        patient_pic: 'assets/admin/img/profiles/avatar-04.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-05.jpg',
        speciality: 'Orthopaedics',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 6,
        revid: '#4546',
        patient_name: 'Deborah',
        doctor_name: 'Dr. Clint',
        patient_pic: 'assets/admin/img/profiles/avatar-11.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-02.jpg',
        speciality: 'Neurology',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 7,
        revid: '#4546',
        patient_name: 'Sofia',
        doctor_name: 'Dr. Richard',
        patient_pic: 'assets/admin/img/profiles/avatar-03.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-10.jpg',
        speciality: 'Orthopaedics',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 8,
        revid: '#4546',
        patient_name: 'Paul',
        doctor_name: 'Dr. Clint',
        patient_pic: 'assets/admin/img/profiles/avatar-13.jpg',
        doctor_pic: 'assets/admin/img/profiles/avatar-02.jpg',
        speciality: 'Neurology',
        ratings: '5',
        description:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        date: 'Wed May 27 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
    ];

    let comments = [
      {
        name: 'Michelle Fairfax',
        email: 'Fairfax@example.com',
        comment:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
      {
        name: 'Elsie Gilley',
        email: 'Fairfax@example.com',
        comment:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam viverra euismod odio, gravida pellentesque urna varius vitae, gravida pellentesque urna varius vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      },
    ];

    let blogs = [
      {
        id: 1,
        title: 'Doccure – Making your clinic painless visit?',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Deborah Angel',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-01.jpg',
        doctor_name: 'Dr. Ruby Perrin',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-01.jpg',
      },
      {
        id: 2,
        title: 'What are the benefits of Online Doctor Booking?',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Deborah Angel',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-02.jpg',
        doctor_name: 'Dr. Darren Elder',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-02.jpg',
      },
      // {
      //   id : 3,
      //   title : "Benefits of consulting with an Online Doctor",
      //   description : "Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.",
      //   createdBy : "Dr. Deborah Angel",
      //   createdAt : "Monday, May 27, 2020 at 9:03:01 AM GMT+01:00",
      //   comments : 12,
      //   type : "Health Tips",
      //   status : "active",
      //   img : "assets/img/blog/blog-03.jpg",
      //   doctor_name : "Dr. Deborah Angel",
      //   blogsource: "video",
      //   videourl: "https://www.youtube.com/embed/nuVqJ_OriR8?rel=0&amp;controls=0&amp;showinfo=0",
      //   doctor_pic : "assets/img/doctors/doctor-thumb-03.jpg"
      // },
      // {
      //   id : 4,
      //   title : "5 Great reasons to use an Online Doctor",
      //   description : "Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.",
      //   createdBy : "Dr. Sofia Brient",
      //   createdAt : "Monday, May 27, 2020 at 9:03:01 AM GMT+01:00",
      //   comments : 12,
      //   type : "Health Tips",
      //   status : "active",
      //   img : "assets/img/blog/blog-04.jpg",
      //   doctor_name : "Dr. Sofia Brient",
      //   blogsource: "video",
      //   videourl: "https://player.vimeo.com/video/133170635",
      //   doctor_pic : "assets/img/doctors/doctor-thumb-04.jpg"
      // },
      {
        id: 5,
        title: 'Online Doctor Appointment Scheduling',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Sofia Brient',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-05.jpg',
        doctor_name: 'Dr. Marvin Campbell',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-05.jpg',
      },
      {
        id: 6,
        title: 'Simple steps to make your doctor visits exceptional!',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Sofia Brient',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-06.jpg',
        doctor_name: 'Dr. Katharine Berthold',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-06.jpg',
      },
      {
        id: 7,
        title: 'Choose your own Online Doctor Appointment',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Sofia Brient',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-07.jpg',
        doctor_name: 'Dr. Linda Tobin',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-07.jpg',
      },
      {
        id: 8,
        title: 'Simple steps to visit your doctor today',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Sofia Brient',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-08.jpg',
        doctor_name: 'Dr. Paul Richard ',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-08.jpg',
      },
      {
        id: 9,
        title: '5 Great reasons to use an Online Doctor',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Sofia Brient',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-09.jpg',
        doctor_name: 'Dr. John Gibbs ',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-09.jpg',
      },
      {
        id: 10,
        title: 'Online Doctoral Programs',
        description:
          'Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.',
        createdBy: 'Dr. Sofia Brient',
        createdAt: 'May 27, 2020',
        comments: 12,
        type: 'Health Tips',
        status: 'active',
        img: 'assets/img/blog/blog-10.jpg',
        doctor_name: 'Dr. Olga Barlow',
        blogsource: 'img',
        videourl: '',
        doctor_pic: 'assets/img/doctors/doctor-thumb-10.jpg',
      },
    ];

    let products = [
      {
        id: 1,
        name: 'Cordacriptine Mardipine',
        img: 'assets/admin/img/products/product14.jpg',
        genetic_name: 'Aripiprazole',
        category: 'Skin care',
        price: '22',
        quantity: '2',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 0,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 2,
        name: 'Abilify',
        genetic_name: 'Aripiprazole',
        img: 'assets/admin/img/products/product.jpg',
        category: 'Phytopathology‎',
        price: '22',
        quantity: '2',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 1,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 3,
        name: 'Rapalac Neuronium',
        img: 'assets/admin/img/products/product13.jpg',
        genetic_name: 'Amphetamine',
        category: 'Skin care',
        price: '16',
        quantity: '216',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 0,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 4,
        name: 'Actamin',
        img: 'assets/admin/img/products/product1.jpg',
        genetic_name: 'Amphetamine',
        category: 'Phytopathology‎',
        price: '22',
        quantity: '5',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 1,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 5,
        name: 'Acetrace Amionel',
        img: 'assets/admin/img/products/product12.jpg',
        genetic_name: 'Amphetamine',
        category: 'Body care',
        price: '10',
        quantity: '22',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 0,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 6,
        name: 'Ergorinex Caffeigestin',
        img: 'assets/admin/img/products/product11.jpg',
        genetic_name: 'Amphetamine',
        category: 'Hair care',
        price: '15',
        quantity: '22',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 0,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 7,
        name: 'Adderall',
        img: 'assets/admin/img/products/product2.jpg',
        genetic_name: 'Amphetamine',
        category: 'Skin care',
        price: '22',
        quantity: '2',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 0,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
      {
        id: 8,
        name: 'Lysofranil Dorzostin',
        img: 'assets/admin/img/products/product10.jpg',
        genetic_name: 'Amphetamine',
        category: 'Hair care',
        price: '10',
        quantity: '22',
        discount: '0',
        expire: 'THE PRODUT IS EXPIRED',
        out_stock: 0,
        created_date: 'Wed May 12 2020 09:41:48 GMT+0530 (India Standard Time)',
      },
    ];

    let transactions = [
      {
        id: 1,
        invoiceNumber: '#IN0001',
        patient_id: '#PT001',
        patient_name: 'Daniel Griffing',
        profile: 'assets/img/patients/patient8.jpg',
        total_amount: '$150.00',
        status: 'paid',
      },
      {
        id: 2,
        invoiceNumber: '#IN0002',
        patient_id: '#PT007',
        patient_name: 'Joan Gardner',
        profile: 'assets/img/patients/patient7.jpg',
        total_amount: '$250.00',
        status: 'paid',
      },
      {
        id: 3,
        invoiceNumber: '#IN0003',
        patient_id: '#PT004',
        patient_name: 'Michelle Fairfax',
        profile: 'assets/img/patients/patient4.jpg',
        total_amount: '$150.00',
        status: 'paid',
      },
      {
        id: 4,
        invoiceNumber: '#IN0004',
        patient_id: '#PT003',
        patient_name: 'Carl Kelly',
        profile: 'assets/img/patients/patient3.jpg',
        total_amount: '$150.00',
        status: 'paid',
      },
      {
        id: 5,
        invoiceNumber: '#IN0005',
        patient_id: '#PT001',
        patient_name: 'Elsie Gilley',
        profile: 'assets/img/patients/patient8.jpg',
        total_amount: '$300.00',
        status: 'paid',
      },
      {
        id: 6,
        invoiceNumber: '#IN0006',
        patient_id: '#PT005',
        patient_name: 'Gina Moore',
        profile: 'assets/img/patients/patient5.jpg',
        total_amount: '$350.00',
        status: 'paid',
      },
      {
        id: 7,
        invoiceNumber: '#IN0007',
        patient_id: '#PT010',
        patient_name: 'Robert Rhodes',
        profile: 'assets/img/patients/patient10.jpg',
        total_amount: '$120.00',
        status: 'paid',
      },
      {
        id: 8,
        invoiceNumber: '#IN0008',
        patient_id: '#PT002',
        patient_name: 'Travis Trimble',
        profile: 'assets/img/patients/patient10.jpg',
        total_amount: '$200.00',
        status: 'paid',
      },
      {
        id: 9,
        invoiceNumber: '#IN0009',
        patient_id: '#PT009',
        patient_name: 'Walter Roberson',
        profile: 'assets/img/patients/patient9.jpg',
        total_amount: '$100.00',
        status: 'paid',
      },
    ];

    let categories = [
      {
        id: '01',
        name: 'Phytopathology‎',
        created_date: 'Wed May 01 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '02',
        name: 'Family care',
        created_date: 'Wed May 03 2020 08:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '03',
        name: 'Cancer',
        created_date: 'Wed May 05 2020 06:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '04',
        name: 'Hair care',
        created_date: 'Wed May 06 2020 01:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '05',
        name: 'Skin care',
        created_date: 'Wed May 05 2020 02:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '06',
        name: 'Rare diseases‎',
        created_date: 'Wed May 06 2020 06:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '07',
        name: 'Baby care',
        created_date: 'Wed May 07 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '08',
        name: 'Inflammations',
        created_date: 'Wed May 07 2020 05:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '09',
        name: 'Sleep disorders‎',
        created_date: 'Wed May 07 2020 07:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: '10',
        name: 'Infectious diseases‎',
        created_date: 'Wed May 08 2020 02:00:00 GMT+0530 (India Standard Time)',
      },
    ];

    let purchase = [
      {
        id: 1,
        date: 'Wed May 10 2020 12:00:00 GMT+0530 (India Standard Time)',
        medicine_name: 'Abilify',
        medicine_img: 'assets/admin/img/products/product.jpg',
        medicine_category: 'Aripiprazole',
        purchase_price: '336',
        quantity: '50',
        supplier: 'Bradley',
        expire_date: 'Wed Jun 13 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: 2,
        date: 'Wed May 11 2020 12:00:00 GMT+0530 (India Standard Time)',
        medicine_name: 'Actamin',
        medicine_img: 'assets/admin/img/products/product2.jpg',
        medicine_category: 'Phytopathology‎',
        purchase_price: '58',
        quantity: '33',
        supplier: 'Douglas',
        expire_date: 'Wed Jun 20 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: 3,
        date: 'Wed May 12 2020 12:00:00 GMT+0530 (India Standard Time)',
        medicine_name: 'Adderall',
        medicine_img: 'assets/admin/img/products/product.jpg',
        medicine_category: 'Phytopathology‎',
        purchase_price: '30',
        quantity: '20',
        supplier: 'Douglas',
        expire_date: 'Wed Jun 13 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: 4,
        date: 'Wed May 13 2020 12:00:00 GMT+0530 (India Standard Time)',
        medicine_name: 'Abilify',
        medicine_img: 'assets/admin/img/products/product.jpg',
        medicine_category: 'Aripiprazole',
        purchase_price: '220',
        quantity: '60',
        supplier: 'Bradley',
        expire_date: 'Wed Jun 13 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: 5,
        date: 'Wed May 08 2020 12:00:00 GMT+0530 (India Standard Time)',
        medicine_name: 'Abilify',
        medicine_img: 'assets/admin/img/products/product2.jpg',
        medicine_category: 'Phytopathology‎',
        purchase_price: '15',
        quantity: '160',
        supplier: 'Douglas',
        expire_date: 'Wed Jun 20 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: 6,
        date: 'Wed May 09 2020 12:00:00 GMT+0530 (India Standard Time)',
        medicine_name: 'Adderall',
        medicine_img: 'assets/admin/img/products/product1.jpg',
        medicine_category: 'Phytopathology‎',
        purchase_price: '10',
        quantity: '605',
        supplier: 'Douglas',
        expire_date: 'Wed Jun 13 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
    ];

    let orders = [
      {
        id: 1,
        supplier_id: '256',
        supplier_name: 'Douglas Meyer',
        order_by: 'Trinity General Hospital',
        add_order: 'Wed Jun 13 2020 12:00:00 GMT+0530 (India Standard Time)',
        order_list: 'Dolofin Antigripal(Dolo)  3',
      },
      {
        id: 2,
        supplier_id: '20',
        supplier_name: 'Tyler Robinson',
        order_by: 'Grand Valley Clinic',
        add_order: 'Wed Jun 10 2020 12:00:00 GMT+0530 (India Standard Time)',
        order_list: 'Dolofin Antigripal(Dolo) 50 Dolofin Antigripal(Dolo)  4',
      },
      {
        id: 3,
        supplier_id: '15',
        supplier_name: 'Mary Dixon',
        order_by: 'Grand Plains Clinic',
        add_order: 'Wed Jun 10 2020 12:00:00 GMT+0530 (India Standard Time)',
        order_list: 'Dolo 650(CIpla)  0',
      },
      {
        id: 4,
        supplier_id: '156',
        supplier_name: 'Kurt Wooten',
        order_by: 'Mercy Vale Cliniclot',
        add_order: 'Wed Jun 10 2020 12:00:00 GMT+0530 (India Standard Time)',
        order_list: 'Tektonik(None)  2',
      },
      {
        id: 5,
        supplier_id: '23',
        supplier_name: 'Vickie Pritchett',
        order_by: 'Kindred Soul Clinic',
        add_order: 'Wed Jun 10 2020 12:00:00 GMT+0530 (India Standard Time)',
        order_list: 'Dolofin Antigripal(Dolo)  4',
      },
      {
        id: 6,
        supplier_id: '205',
        supplier_name: 'Christopher Johnson',
        order_by: 'Hill Crest Clinic',
        add_order: 'Wed Jun 10 2020 12:00:00 GMT+0530 (India Standard Time)',
        order_list: 'Dolofin Antigripal(Dolo)  50 Dolofin Antigripal(Dolo)  4',
      },
    ];

    let sales = [
      {
        invoice_number: '20169998',
        machine_name: 'Abilify',
        total_price: '150',
        date: 'Wed May 14 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        invoice_number: '20169999',
        machine_name: 'Abilify, Actamin',
        total_price: '100',
        date: 'Wed May 13 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        invoice_number: '20170001',
        machine_name: 'Abilify, Actamin, Actamin',
        total_price: '220',
        date: 'Wed May 12 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        invoice_number: '20170001',
        machine_name: 'Abilify, Actamin, Actamin',
        total_price: 'Douglas Meyer',
        date: 'Wed Jun 15 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
    ];
    let suppliers = [
      {
        id: 25,
        product_name: 'Abilify',
        product_img: '',
        supplier_name: 'Discarsa',
        phone_number: '+51 1542565',
        email: 'Discarsa@gmail.com',
        address: 'Paseo Los Robles',
        company: 'Discarsa Nicaragua',
      },
      {
        id: 33,
        product_name: 'Actamin',
        product_img: '',
        supplier_name: 'Proveedor',
        phone_number: '+51 23687541',
        email: 'proveedor@gmail.com',
        address: 'Av circunvalación # 234',
        company: 'mi farmacia',
      },
      {
        id: 42,
        product_name: 'Adderall',
        product_img: '',
        supplier_name: 'Discarsa',
        phone_number: '+51 96588552',
        email: 'Discarsa@gmail.com',
        address: 'Paseo Los Robles',
        company: 'Discarsa Nicaragua',
      },
    ];

    let pharmacy_transactions = [
      {
        id: 1,
        invoice_number: '#IN0001',
        product_id: '#01',
        product_img: 'assets/admin/img/products/product.jpg',
        product_name: 'Abilify',
        total_amount: '100',
        status: 'paid',
      },
      {
        id: 2,
        invoice_number: '#IN0002',
        product_id: '#02',
        product_img: 'assets/admin/img/products/product13.jpg',
        product_name: 'ITONE eye drops 10ml',
        total_amount: '200',
        status: 'paid',
      },
      {
        id: 3,
        invoice_number: '#IN0003',
        product_id: '#03',
        product_img: 'assets/admin/img/products/product4.jpg',
        product_name: 'Safi Natural Blood Purifier',
        total_amount: '250',
        status: 'paid',
      },
      {
        id: 4,
        invoice_number: '#IN0004',
        product_id: '#04',
        product_img: 'assets/admin/img/products/product5.jpg',
        product_name: 'Moisturization & Nourishment',
        total_amount: '150',
        status: 'paid',
      },
      {
        id: 5,
        invoice_number: '#IN0005',
        product_id: '#05',
        product_img: 'assets/admin/img/products/product6.jpg',
        product_name: 'Blackhead Removal',
        total_amount: '350',
        status: 'paid',
      },
      {
        id: 6,
        invoice_number: '#IN0006',
        product_id: '#06',
        product_img: 'assets/admin/img/products/product.jpg',
        product_name: 'Abilify',
        total_amount: '300',
        status: 'paid',
      },
      {
        id: 7,
        invoice_number: '#IN0007',
        product_id: '#07',
        product_img: 'assets/admin/img/products/product6.jpg',
        product_name: 'Blackhead Removal',
        total_amount: '250',
        status: 'paid',
      },
      {
        id: 8,
        invoice_number: '#IN0008',
        product_id: '#08',
        product_img: 'assets/admin/img/products/product3.jpg',
        product_name: 'ITONE eye drops 10ml',
        total_amount: '150',
        status: 'paid',
      },
      {
        id: 9,
        invoice_number: '#IN0009',
        product_id: '#09',
        product_img: 'assets/admin/img/products/product.jpg',
        product_name: 'Whitening & Fairness',
        total_amount: '100',
        status: 'paid',
      },
      {
        id: 10,
        invoice_number: '#IN0010',
        product_id: '#10',
        product_img: 'assets/admin/img/products/product1.jpg',
        product_name: 'Adderall',
        total_amount: '120',
        status: 'paid',
      },
    ];

    let pharmacy_reports = [
      {
        id: 1,
        invoice_number: '#IN0001',
        medicine_name: 'Abilify, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '100',
        created_date: 'Wed Sep 09 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
      {
        id: 2,
        invoice_number: '#IN0002',
        medicine_name: 'Abilify, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '200',
        created_date: 'Wed Sep 25 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
      {
        id: 3,
        invoice_number: '#IN0003',
        medicine_name: 'Abilify, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '250',
        created_date: 'Wed Oct 25 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'pending',
      },
      {
        id: 4,
        invoice_number: '#IN0004',
        medicine_name: 'Abilify, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '150',
        created_date: 'Wed Sep 09 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'unpaid',
      },
      {
        id: 5,
        invoice_number: '#IN0005',
        medicine_name: 'Abilify, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '350',
        created_date: 'Wed Nov 19 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
      {
        id: 6,
        invoice_number: '#IN0001',
        medicine_name: 'Abilify, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '300',
        created_date: 'Wed Oct 12 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
      {
        id: 7,
        invoice_number: '#IN0007',
        medicine_name: 'Abilify, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '100',
        created_date: 'Wed Oct 25 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
      {
        id: 8,
        invoice_number: '#IN0008',
        medicine_name: 'Abilify, Actamin, Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '150',
        created_date: 'Wed Sep 09 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
      {
        id: 9,
        invoice_number: '#IN0009',
        medicine_name: 'Abilify',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '100',
        created_date: 'Wed Nov 05 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
      {
        id: 10,
        invoice_number: '#IN0010',
        medicine_name: 'Abilify,Actamin',
        medicine_img: 'assets/admin/img/products/product.jpg',
        total_amount: '100',
        created_date: 'Wed Nov 07 2020 12:00:00 GMT+0530 (India Standard Time)',
        status: 'paid',
      },
    ];

    let pharmacy = [
      {
        id: 1,
        pharmacy_id: '#SP001',
        img: 'assets/img/features/feature-02.jpg',
        name: 'The Pill Club Medical',
        addr: '3503 Flint Street Saipan, MP 96950',
        phone: '816-270-2336',
        created_date: 'Wed May 12 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: 2,
        pharmacy_id: '#SP002',
        img: 'assets/img/features/feature-02.jpg',
        name: 'PharmaMed Medical',
        addr: '3130 Murry Street Princess Anne, VA 23456',
        phone: '913-631-2538',
        created_date: 'Wed May 12 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
      {
        id: 3,
        pharmacy_id: '#SP003',
        img: 'assets/img/features/feature-02.jpg',
        name: 'Medlife Medical',
        addr: '96 Red Hawk Road Cyrus, MN 56323',
        phone: '320-795-8815',
        created_date: 'Wed May 12 2020 12:00:00 GMT+0530 (India Standard Time)',
      },
    ];
    return {
      pharmacy: pharmacy,
      pharmacy_reports: pharmacy_reports,
      pharmacy_transactions: pharmacy_transactions,
      supplier: suppliers,
      sales: sales,
      orders: orders,
      purchase: purchase,
      categories: categories,
      products: products,
      transactions: transactions,
      comments: comments,
      favourites: favourites,
      blogs: blogs,
      specialityList: specialityList,
      appointments: appointments,
      doctors: doctors,
      patients: patients,
      reviews: reviews,
      searchDoctor,
    };
  }
}
